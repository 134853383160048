import React, { useCallback, useEffect, useMemo, useState } from "react";
import OutrasInformacoesImovelDefaultForm from "./OutrasInformacoesImovelDefaultForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";

const OutrasInformacoesImovelDefaultFormConnected = ({
  handleGoTo102,
  eventsImovel,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { operacao, validacao } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);

  const imovel = useMemo(
    () => ({
      ...operacao.imovel,
      fluxo: validacao.completa.imovel,
    }),
    [operacao, validacao]
  );
  const [parteImovel, setParteImovel] = useState(
    imovel.adquirindoParteImovel ?? false
  );

  const validatePercentualAquisicao = {
    checkTotal: () => {
      return formPropsPercentualAquisicao
        .getValues()
        .participantes.reduce(
          (acc, v) => acc + v.percentualAquisicaoImovel,
          0
        ) !== 100
        ? "A soma dos percentuais deve ser igual a 100%"
        : true;
    },
  };
  const initialValuesParticipantes = () => ({
    participantes:
      operacao.participantes.filter((p) => p.tipoParticipante === "CO")
        .length === 1
        ? operacao.participantes
            .filter((p) => p.tipoParticipante === "CO")
            .map((p) => ({ ...p, percentualAquisicaoImovel: 100.0 }))
        : operacao.participantes.filter((p) => p.tipoParticipante === "CO"),
  });

  const [formPropsPercentualAquisicao] = useCompleteForm({
    rules: () => ({
      participantes: {
        id: validators.number({ required: true }),
        nome: validators.string({ required: true }),
        percentualAquisicaoImovel: validators.number({
          required: true,
          custom: validatePercentualAquisicao,
        }),
      },
    }),
    initialValues: initialValuesParticipantes,
  });

  const [formProps, submitForm] = useCompleteForm({
    rules: () => ({
      numeroDormitorios: validators.number({ required: true }),
      parteAdquirida: validators.number({ required: true }),
      valorParteAdquirida: validators.number({ required: true }),
    }),
    initialValues: useCallback(
      () => ({
        numeroDormitorios: "",
        vagaAutonoma: false,
        programaSocial: false,
        imovelFinanciado: false,
        ...(operacao.tipoProduto === "I"
          ? {
              adquirindoParteImovel: false,
              parteAdquirida: "",
              valorParteAdquirida: "",
              primeiraAquisicaoSfh: false,
            }
          : {}),
        ...(operacao.tipoProduto === "H"
          ? {
              imovelProprio: false,
            }
          : {}),
        ...imovel,
      }),
      [imovel, operacao]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback: handleGoTo102,
            instituicao: operacao.codigoInstituicao,
            passo: "outras-informacoes-imovel",
            operacao: {
              id: operacao.id,
              imovel: {
                numeroDormitorios: values.numeroDormitorios,
                imovelProprio: values.imovelProprio,
                programaSocial: values.programaSocial,
                possuiDepositoVagaAutonoma: values.vagaAutonoma,
                adquirindoParteImovel: values.adquirindoParteImovel,
                parteAdquirida: !!values.adquirindoParteImovel
                  ? values.parteAdquirida
                  : null,
                valorParteAdquirida: !!values.adquirindoParteImovel
                  ? values.valorParteAdquirida
                  : null,
                imovelFinanciado: values.imovelFinanciado,
                primeiraAquisicaoSfh: values.primeiraAquisicaoSfh,
              },
              participantes: formPropsPercentualAquisicao
                .getValues()
                .participantes.map((p) => ({
                  id: p.id,
                  percentualAquisicaoImovel: p.percentualAquisicaoImovel,
                })),
            },
          })
        );
      },
      [dispatch, operacao, handleGoTo102, formPropsPercentualAquisicao]
    ),
  });
  const handleChangeParteImovel = useCallback(
    ({ target: { checked } }) => {
      setParteImovel(checked);
    },
    [setParteImovel]
  );

  const { reset } = formProps;

  useEffect(() => {
    reset({
      numeroDormitorios: "",
      vagaAutonoma: false,
      programaSocial: false,
      imovelFinanciado: false,
      ...(operacao.tipoProduto === "I"
        ? {
            adquirindoParteImovel: false,
            parteAdquirida: "",
            valorParteAdquirida: "",
            primeiraAquisicaoSfh: false,
          }
        : {}),
      ...(operacao.tipoProduto === "H"
        ? {
            imovelProprio: false,
          }
        : {}),
      ...imovel,
    });
  }, [imovel, reset, operacao]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isPercentValid = await formPropsPercentualAquisicao.trigger();
    const isMainFormValid = await formProps.trigger();

    if (isPercentValid && isMainFormValid) {
      submitForm();
    }
  };

  return (
    <OutrasInformacoesImovelDefaultForm
      {...otherProps}
      formProps={formProps}
      tipoProduto={operacao.tipoProduto}
      handleSubmit={handleSubmit}
      parteImovel={parteImovel}
      handleChangeParteImovel={handleChangeParteImovel}
      submitting={submitting}
      fluxo={imovel.fluxo}
      events={eventsImovel}
      operacao={operacao}
      formPropsPercentualAquisicao={formPropsPercentualAquisicao}
    />
  );
};

export default OutrasInformacoesImovelDefaultFormConnected;
