import {
  Checkbox,
  FormControlLabel,
  Grid,
  styled,
  TableBody,
  TableCell,
  TableContainer as MTableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Button, Card, Form, GoBack, Title } from "../../../../components";
import {
  ControlledTextField,
  ControlledAutoComplete,
  PercentTextField,
} from "../../../../components/inputs";
import {
  BodyTableRow,
  SizedTableCell,
  Table,
} from "../../../../components/tables";
import {
  AccountTextField,
  IntegerTextField,
} from "../../../../components/inputs";
import useResponsive from "../../../../hooks/useResponsive";

const TableContainer = styled(MTableContainer)`
  & .MuiTable-root {
    margin-top: 0;
  }

  & .MuiTableHead-root .MuiTableCell-head {
    padding-top: 0;
  }
`;
const ContaContainer = styled(Grid)`
  border: 1px solid ${({ theme }) => theme.colors.gray._50};
  border-radius: 1rem;
  padding: 1rem;
`;

const formatWithCodeAndDescription = (option, domain) => {
  if (!!option) {
    const domainOption = domain.find(
      (d) => d.id === option.id || d.id === option
    );
    if (!!domainOption) {
      return `${domainOption.codigo} - ${domainOption.nome}`;
    }
  }
  return "";
};

const ContasMobile = ({
  contas,
  contasArray,
  habilitados,
  handleChangeCheck,
  bancos,
  formProps,
  tiposConta,
}) => {
  return (
    <Grid container spacing={1}>
      {contas.map((conta, index) => {
        let i = null;
        contasArray.fields.forEach((v, x) => {
          if (v.index === index) {
            i = x;
          }
        });
        return (
          <Grid item key={conta.participante}>
            <ContaContainer>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"selecionado"}
                        checked={habilitados[index]}
                        onChange={() => handleChangeCheck(index)}
                      />
                    }
                    label={conta.nome}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledAutoComplete
                    domain={bancos}
                    label={"Banco"}
                    idField={"id"}
                    getOptionLabel={(option) =>
                      formatWithCodeAndDescription(option, bancos)
                    }
                    name={`contas.${i}.bancoConta`}
                    validationKey={`contas.bancoConta`}
                    formProps={formProps}
                    autocompleteProps={{
                      disabled: !habilitados[index],
                      clearText: "Limpar",
                      loadingText: "Carregando opções",
                      noOptionsText: "Nenhuma opção disponível",
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <ControlledTextField
                    disabled={!habilitados[index]}
                    label={"Agência"}
                    name={`contas.${i}.agenciaConta`}
                    validationKey={`contas.agenciaConta`}
                    inputProps={{
                      maxLength: 4,
                      allowLeadingZeros: true,
                      asString: true,
                      allowNegative: false,
                    }}
                    formProps={formProps}
                    InputProps={{
                      inputComponent: IntegerTextField,
                    }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <ControlledTextField
                    disabled={!habilitados[index]}
                    label={"Conta"}
                    name={`contas.${i}.numeroConta`}
                    validationKey={`contas.numeroConta`}
                    formProps={formProps}
                    InputProps={{
                      inputComponent: AccountTextField,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledAutoComplete
                    domain={tiposConta}
                    idField={"id"}
                    label={"Tipo"}
                    labelField={"descricao"}
                    name={`contas.${i}.tipoConta`}
                    validationKey={`contas.tipoConta`}
                    formProps={formProps}
                    autocompleteProps={{
                      disabled: !habilitados[index],
                      clearText: "Limpar",
                      loadingText: "Carregando opções",
                      noOptionsText: "Nenhuma opção disponível",
                    }}
                  />
                </Grid>
              </Grid>
            </ContaContainer>
          </Grid>
        );
      })}
    </Grid>
  );
};

const ContasDesktop = ({
  contas,
  habilitados,
  handleChangeCheck,
  bancos,
  contasArray,
  formProps,
  tiposConta,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <SizedTableCell width={56}></SizedTableCell>
            <TableCell width={"25%"}>Nome</TableCell>
            <TableCell width={"25%"}>Banco</TableCell>
            <TableCell width={"10%"}>Agência</TableCell>
            <TableCell width={"15%"}>Conta</TableCell>
            <TableCell width={"15%"}>Tipo</TableCell>
            <TableCell width={"10%"}>% liberação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contas.map((conta, index) => {
            let i = null;
            contasArray.fields.forEach((v, x) => {
              if (v.index === index) {
                i = x;
              }
            });
            return (
              <BodyTableRow key={conta.participante} index={index}>
                <SizedTableCell width={56}>
                  <Checkbox
                    name={"selecionado"}
                    checked={habilitados[index]}
                    onChange={() => handleChangeCheck(index)}
                  />
                </SizedTableCell>
                <TableCell>{conta.nome}</TableCell>
                <TableCell>
                  <ControlledAutoComplete
                    domain={bancos}
                    idField={"id"}
                    getOptionLabel={(option) =>
                      formatWithCodeAndDescription(option, bancos)
                    }
                    name={`contas.${i}.bancoConta`}
                    validationKey={`contas.bancoConta`}
                    formProps={formProps}
                    autocompleteProps={{
                      disabled: !habilitados[index],
                      clearText: "Limpar",
                      loadingText: "Carregando opções",
                      noOptionsText: "Nenhuma opção disponível",
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ControlledTextField
                    disabled={!habilitados[index]}
                    name={`contas.${i}.agenciaConta`}
                    validationKey={`contas.agenciaConta`}
                    inputProps={{
                      maxLength: 4,
                      allowLeadingZeros: true,
                      asString: true,
                    }}
                    formProps={formProps}
                    InputProps={{
                      inputComponent: IntegerTextField,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ControlledTextField
                    disabled={!habilitados[index]}
                    name={`contas.${i}.numeroConta`}
                    validationKey={`contas.numeroConta`}
                    formProps={formProps}
                    InputProps={{
                      inputComponent: AccountTextField,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ControlledAutoComplete
                    domain={tiposConta}
                    idField={"id"}
                    labelField={"descricao"}
                    name={`contas.${i}.tipoConta`}
                    validationKey={`contas.tipoConta`}
                    formProps={formProps}
                    autocompleteProps={{
                      disabled: !habilitados[index],
                      clearText: "Limpar",
                      loadingText: "Carregando opções",
                      noOptionsText: "Nenhuma opção disponível",
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ControlledTextField
                    disabled={!habilitados[index]}
                    name={`contas.${i}.percentualLiberacao`}
                    validationKey={`contas.percentualLiberacao`}
                    onChange={() => formProps.formState.isSubmitted && formProps.trigger()}
                    formProps={formProps}
                    InputProps={{
                      inputComponent: PercentTextField,
                    }}
                  />
                </TableCell>
              </BodyTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ContasLiberacaoVendedorItauForm = ({
  formProps,
  handleSubmit,
  contas,
  contasArray,
  bancos,
  tiposConta,
  submitting,
  habilitados,
  handleChangeCheck,
  handleGoBack,
}) => {
  const currentSize = useResponsive();
  const isMobile = currentSize === "xs" || currentSize === "sm";

  return (
    <>
      <GoBack
        handleClick={handleGoBack}
        text={"Selecione ao menos uma conta para receber o valor do imóvel"}
      />
      <Grid item container spacing={3}>
        <Grid item container justifyContent={"center"}>
          <Grid item xs={12} xl={8}>
            <Card>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Title as={"h5"}>Contas para Liberação</Title>
                  </Grid>
                  <Grid item xs={12}>
                    {!!isMobile && (
                      <ContasMobile
                        contas={contas}
                        contasArray={contasArray}
                        formProps={formProps}
                        tiposConta={tiposConta}
                        bancos={bancos}
                        habilitados={habilitados}
                        handleChangeCheck={handleChangeCheck}
                      />
                    )}
                    {!isMobile && (
                      <ContasDesktop
                        contas={contas}
                        contasArray={contasArray}
                        formProps={formProps}
                        tiposConta={tiposConta}
                        bancos={bancos}
                        habilitados={habilitados}
                        handleChangeCheck={handleChangeCheck}
                      />
                    )}
                  </Grid>
                  <Grid item container justifyContent={"center"} mt={1}>
                    <Grid item xs={12} md={4}>
                      <Button
                        type={"submit"}
                        fullWidth
                        disabled={habilitados.filter((x) => !!x).length === 0}
                        loading={submitting}
                      >
                        CONTINUAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContasLiberacaoVendedorItauForm;
