import React, { useCallback, useEffect, useMemo, useState } from "react";
import OutrasInformacoesImovelItauForm from "./OutrasInformacoesImovelItauForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isBuscandoContaIq,
  isGravandoComplementoOperacao,
} from "../../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../../hooks/useCompleteForm";
import validators from "../../../../../utils/validators";
import { actions } from "../../../../../actions/operacao.actions";
import formatters from "../../../../../utils/formatters";
import { removeNonDigitsFromString } from "../../../../../utils/basic";

const OutrasInformacoesImovelItauFormConnected = ({
  handleGoTo102,
  eventsImovel,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    validacao,
    dominios: { banco: bancos },
  } = useSelector(getComplementoOperacao);
  const searching = useSelector(isBuscandoContaIq);
  const [desabilitarEdicaoIq, setDesabilitarEdicaoIq] = useState(true);

  const submitting = useSelector(isGravandoComplementoOperacao);

  const imovel = useMemo(
    () => ({
      ...operacao.imovel,
      fluxo: validacao.completa.imovel,
    }),
    [operacao, validacao]
  );

  useEffect(() => {
    if (imovel.contatoIq?.email || imovel.contatoIq?.telefone) {
      setDesabilitarEdicaoIq(false);
    }
  }, [imovel]);

  const [parteImovel, setParteImovel] = useState(
    imovel.adquirindoParteImovel ?? false
  );

  const validatePercentualAquisicao = {
    checkTotal: () => {
      return formPropsPercentualAquisicao
        .getValues()
        .participantes.filter((p) => p.tipoParticipante === "CO")
        .reduce((acc, v) => acc + v.percentualAquisicaoImovel, 0) !== 100
        ? "A soma dos percentuais deve ser igual a 100%"
        : true;
    },
  };
  const initialValuesParticipantes = () => ({
    participantes:
      operacao.participantes.filter((p) => p.tipoParticipante === "CO")
        .length === 1
        ? operacao.participantes
            .filter((p) => p.tipoParticipante === "CO")
            .map((p) => ({ ...p, percentualAquisicaoImovel: 100.0 }))
        : operacao.participantes.filter((p) => p.tipoParticipante === "CO"),
  });

  const [formPropsPercentualAquisicao] = useCompleteForm({
    rules: () => ({
      participantes: {
        id: validators.number({ required: true }),
        nome: validators.string({ required: true }),
        percentualAquisicaoImovel: validators.number({
          required: true,
          custom: validatePercentualAquisicao,
        }),
      },
    }),
    initialValues: initialValuesParticipantes,
  });

  const [formProps, submitForm] = useCompleteForm({
    rules: () => ({
      quantidadeVagas: validators.number({ required: true }),
      numeroDormitorios: validators.number({ required: true }),
      parteAdquirida: validators.number({ required: true }),
      valorParteAdquirida: validators.number({ required: true }),
      numeroContribuinte: validators.number({ required: true }),
      matricula: validators.string({ required: true }),
      cartorio: {
        numero: validators.string({ required: true }),
        municipio: validators.string({ required: true }),
        uf: validators.string({ required: true }),
      },
      contatoIq: {
        banco: validators.object({ required: true }),
        nomeContato: validators.string({ required: true }),
        email: validators.email({ required: true }),
        telefone: validators.phone({ required: true }),
      },
    }),
    initialValues: useCallback(
      () => ({
        numeroDormitorios: "",
        quantidadeVagas: "",
        vagaAutonoma: false,
        programaSocial: false,
        imovelFinanciado: false,
        matricula: "",
        ...(operacao.tipoProduto === "I"
          ? {
              adquirindoParteImovel: false,
              parteAdquirida: "",
              valorParteAdquirida: "",
              primeiraAquisicaoSfh: false,
            }
          : {}),
        ...(operacao.tipoProduto === "H"
          ? {
              imovelProprio: false,
            }
          : {}),
        ...imovel,
        cartorio: {
          numero: "",
          municipio: "",
          uf: "",
          ...imovel.cartorio,
        },
        contatoIq: {
          email: "",
          banco: null,
          telefone: "",
          nomeContato: "",
          ...imovel.contatoIq,
        },
      }),
      [imovel, operacao, bancos]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback: handleGoTo102,
            instituicao: operacao.codigoInstituicao,
            passo: "outras-informacoes-imovel",
            operacao: {
              id: operacao.id,
              imovel: {
                quantidadeVagas: values.quantidadeVagas,
                numeroDormitorios: values.numeroDormitorios,
                imovelProprio: values.imovelProprio,
                programaSocial: values.programaSocial,
                possuiDepositoVagaAutonoma: values.vagaAutonoma,
                adquirindoParteImovel: values.adquirindoParteImovel,
                parteAdquirida: !!values.adquirindoParteImovel
                  ? values.parteAdquirida
                  : null,
                valorParteAdquirida: !!values.adquirindoParteImovel
                  ? values.valorParteAdquirida
                  : null,
                imovelFinanciado: values.imovelFinanciado,
                primeiraAquisicaoSfh: values.primeiraAquisicaoSfh,
                numeroContribuinte: values.numeroContribuinte,
                matricula: values.matricula,
                cartorio: {
                  numero: values.cartorio.numero,
                  municipio: values.cartorio.municipio,
                  uf: values.cartorio.uf,
                },
                contatoIq: !!values.imovelFinanciado
                  ? {
                      idBanco: values.contatoIq.banco.id,
                      nomeContato: values.contatoIq.nomeContato,
                      email: values.contatoIq.email,
                      telefone: values.contatoIq.telefone,
                    }
                  : null,
              },
              participantes: formPropsPercentualAquisicao
                .getValues()
                .participantes.map((p) => ({
                  id: p.id,
                  percentualAquisicaoImovel: p.percentualAquisicaoImovel,
                })),
            },
          })
        );
      },
      [dispatch, operacao, handleGoTo102, formPropsPercentualAquisicao]
    ),
  });
  const handleChangeParteImovel = useCallback(
    ({ target: { checked } }) => {
      setParteImovel(checked);
    },
    [setParteImovel]
  );

  const { reset } = formProps;

  useEffect(() => {
    reset({
      numeroDormitorios: "",
      vagaAutonoma: false,
      programaSocial: false,
      imovelFinanciado: false,
      ...(operacao.tipoProduto === "I"
        ? {
            adquirindoParteImovel: false,
            parteAdquirida: "",
            valorParteAdquirida: "",
            primeiraAquisicaoSfh: false,
          }
        : {}),
      ...(operacao.tipoProduto === "H"
        ? {
            imovelProprio: false,
          }
        : {}),
      ...imovel,
      contatoIq: {
        email: "",
        banco: null,
        telefone: "",
        nomeContato: "",
        ...imovel.contatoIq,
      },
    });
  }, [imovel, reset, operacao]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isPercentValid = await formPropsPercentualAquisicao.trigger();
    const isMainFormValid = await formProps.trigger();

    if (isPercentValid && isMainFormValid) {
      submitForm();
    }
  };

  const successCallback = useCallback(
    ({ data }) => {
      setDesabilitarEdicaoIq(false);
      formProps.setValue(
        "contatoIq",
        {
          ...formProps.getValues().contatoIq,
          nomeContato: data?.nomeContato || "",
          telefone: formatters.strings.phone(data?.telefone) || "",
          email: data?.email || "",
        },
        { shouldValidate: !!data }
      );
      setDesabilitarEdicaoIq(!!data);
    },
    [formProps]
  );

  const handleBancoIqChange = useCallback(
    (banco) => {
      if (!!banco) {
        setDesabilitarEdicaoIq(true);
        dispatch(
          actions.obterContatoIq.request({
            id: banco.id,
            successCallback,
          })
        );
      }
    },
    [dispatch, successCallback]
  );

  return (
    <OutrasInformacoesImovelItauForm
      {...otherProps}
      formProps={formProps}
      tipoProduto={operacao.tipoProduto}
      handleSubmit={handleSubmit}
      parteImovel={parteImovel}
      handleChangeParteImovel={handleChangeParteImovel}
      submitting={submitting}
      fluxo={imovel.fluxo}
      events={eventsImovel}
      operacao={operacao}
      formPropsPercentualAquisicao={formPropsPercentualAquisicao}
      bancos={bancos}
      desabilitarEdicaoIq={desabilitarEdicaoIq}
      searching={searching}
      handleBancoIqChange={handleBancoIqChange}
    />
  );
};

export default OutrasInformacoesImovelItauFormConnected;
