import React, { useCallback, useMemo, useState } from "react";
import ContasLiberacaoVendedorDefaultForm from "./ContasLiberacaoVendedorDefaultForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplementoOperacao,
  isEnviandoComplementoOperacao,
  isGravandoComplementoOperacao,
} from "../../../../selectors/operacao.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import { useFieldArray } from "react-hook-form";
import { actions } from "../../../../actions/operacao.actions";
import validators from "../../../../utils/validators";
import {
  actions as routeActions,
  types as routes,
} from "../../../../actions/rotas.actions";

const ContasLiberacaoVendedorDefaultFormConnected = ({
  handleGoTo0,
  vendedores,
}) => {
  const dispatch = useDispatch();
  const {
    operacao,
    dominios: { tipoConta: tiposConta, banco: bancos },
  } = useSelector(getComplementoOperacao);
  const submitting = useSelector(isGravandoComplementoOperacao);
  const submittingDocumentos = useSelector(isEnviandoComplementoOperacao);

  const contas = useMemo(() => {
    return vendedores.map((v, i) => {
      if (v.contaLiberacao) {
        return {
          ...v.contaLiberacao,
          index: i,
          nome: v.nome,
          participante: v.id,
        };
      }

      return {
        index: i,
        nome: v.nome,
        participante: v.id,
      };
    });
  }, [vendedores]);

  const [habilitados, setHabilitados] = useState(contas.map((c) => !!c.id));

  const handleEnviarDocumentos = useCallback(() => {
    dispatch(
      actions.enviarComplemento.request({
        id: operacao.id,
      })
    );
  }, [dispatch, operacao]);

  const validatePercentualLiberacao = {
    checkTotal: () => {
      return formProps.getValues().contas.filter(c => habilitados[c.index]).reduce((acc, v) => acc + v.percentualLiberacao, 0) !== 100 ? "A soma dos percentuais deve ser igual a 100%" : true;
    },
  };

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      contas: {
        bancoConta: validators.object({ required: true }),
        agenciaConta: validators.string({ required: true }),
        numeroConta: validators.string({ required: true }),
        tipoConta: validators.object({ required: true }),
        percentualLiberacao: validators.number({ required: true, custom: validatePercentualLiberacao }),
      },
    }),
    initialValues: useCallback(
      () => ({
        contas: contas.filter((c) => !!c.id),
      }),
      [contas]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.gravarComplemento.request({
            callback: () => handleGoTo0(),
            instituicao: operacao.codigoInstituicao,
            passo: "contas-liberacao",
            operacao: {
              id: operacao.id,
              participantes: values.contas.map((v) => {
                return {
                  id: v.participante,
                  contaLiberacao: !!v.numeroConta
                    ? {
                      id: v.id,
                      idBancoConta: v.bancoConta?.id,
                      agenciaConta: v.agenciaConta,
                      numeroConta: v.numeroConta,
                      idTipoConta: v.tipoConta?.id,
                      percentualLiberacao: v.percentualLiberacao,
                    }
                    : null,
                };
              }),
            },
          })
        );
      },
      [dispatch, operacao, handleEnviarDocumentos]
    ),
  });

  const contasArray = useFieldArray({
    control: formProps.control,
    name: "contas",
  });

  const handleChangeCheck = useCallback(
    (index) => {
      if (!!habilitados[index]) {
        let i = null;
        contasArray.fields.forEach((v, x) => {
          if (v.index === i) {
            i = x;
          }
        });
        contasArray.remove(i);
      } else {
        contasArray.append({
          ...contas[index],
          bancoConta: null,
          agenciaConta: "",
          numeroConta: "",
          tipoConta: null,
          percentualLiberacao: ""
        });
      }
      setHabilitados(habilitados.map((h, i) => (i === index ? !h : h)));
    },
    [habilitados, setHabilitados, contas, contasArray]
  );

  return (
    <ContasLiberacaoVendedorDefaultForm
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting || submittingDocumentos}
      contas={contas}
      contasArray={contasArray}
      bancos={bancos}
      tiposConta={tiposConta}
      habilitados={habilitados}
      handleChangeCheck={handleChangeCheck}
      handleGoBack={handleGoTo0}
    />
  );
};

export default ContasLiberacaoVendedorDefaultFormConnected;
